import React from 'react'

export default function Footer() {

    return (
        <footer className="footer container-fluid  pb-5 bg-dark text-light">
            <div className="row">
                <div className="col-sm text-left m-5">
                    <h5>Site Navigation</h5>
                    <a className="text-light text-left" href="/">Home</a><br />
                    <a className="text-light text-left" href="/software">Software</a><br />
                    <a className="text-light text-left" href="/photography">Photography</a><br />
                    <a className="text-light text-left" href="/drone">Drone</a><br />
                    <a className="text-light text-left" href="/contact">Contact</a><br />
                </div>
                <div className="col-sm text-left m-5">
                    <h5>Info</h5>
                    <a className="text-light text-left" href="https://github.com/aj-medianet" target="_blank" rel="noopener noreferrer">GitHub</a><br />
                    <a className="text-light text-left" href="mailto:andrew@aj-media.net" target="_blank" rel="noopener noreferrer">andrew@aj-media.net</a><br />
                </div>
                <div className="col-sm text-left m-5">
                    <h5>Social</h5>
                    <a className="text-light text-left" href="https://www.linkedin.com/in/andrew-joseph-816624133/" target="_blank" rel="noopener noreferrer">LinkedIn</a><br />
                    <a className="text-light text-left" href="https://www.instagram.com/aj__media/" target="_blank" rel="noopener noreferrer">Instagram</a><br />
                </div>
            </div>
            <div className="row">
                <div className="col-sm text-center">
                    &copy; AJ MEDIA | {new Date().getFullYear()}
                </div>
            </div>
        </footer>
    )
}
