import React from 'react';

export default function TimerPrivactPolicy() {
    return (
        <>
            <div className="row">
                <div className="col-sm py-5">
                    <h1>Random Game Timer Privacy Policy</h1>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-sm">
                    <img className="img-fluid" src="images/rgt-logo.png" alt="timer logo" />
                </div>
                <div className="col-sm">
                    <p>
                        This iOS app does not collect any information about its user. By using my app, you consent to this privacy policy.
                        </p>
                </div>
            </div>


        </>

    )
}
