import React from 'react';

export default function Drone() {
    return (
        <>
            <img src="/images/drone.jpg" className="img-fluid" alt="Drone Banner" />
            <div className="row mt-5">
                <div className="col-sm ">
                    <img src="/images/gr2.jpg" className="img-fluid" alt="gr2" />
                </div>
                <div className="col-sm ">
                    <img src="/images/quakelake.jpg" className="img-fluid" alt="quakelake" />
                </div>
            </div>

            <div className="my-4 row">
                <div className="col-sm">
                    <div className="videoWrapper">
                        <iframe title="Southern Utah" src="https://www.youtube.com/embed/xbXg6rCvK9o" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div className="my-4 row">
                <div className="col-sm">
                    <div className="videoWrapper">
                        <iframe title="Mt Timpanogos" src="https://www.youtube.com/embed/Ejgd_6LjSCQ" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div className="my-4 row">
                <div className="col-sm">
                    <div className="videoWrapper">
                        <iframe title="Bozeman Sunrise" src="https://www.youtube.com/embed/4wkScC4fnak" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}


