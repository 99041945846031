import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle';

export default function Navigation() {
    return (
        <header className="">
            <nav className="navbar navbar-expand-lg navbar-dark bg-black">
                <a className="navbar-brand" href="/">AJ MEDIA</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="/software">Software</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/photography">Photography</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/drone">Drone</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}
