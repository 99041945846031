import React from 'react';


export default function MainContent() {
  return (
    <>
      <div className="row">
        <div className="col-sm photo">
          <img src="/images/glacierlogo.jpg" className="banner" alt="Main Page Banner" />
        </div>
      </div>

      <div className="my-5 row">
        <div className="col-sm">
          <a href="/software" className="">
            <img src="/images/ec.jpg" className="p-3 img-fluid" alt="Software Page" />
          </a>
          <a href="software" className="text-dark">
            <h3 className="mt-3">Software</h3>
          </a>
        </div>
        <div className="col-sm">
          <a href="/photography" className="text-dark">
            <img src="/images/pow.jpg" className="p-3 img-fluid" alt="Photography Page" />
          </a>
          <a href="photography" className="text-dark">
            <h3 className="mt-3">Photography</h3>
          </a>
        </div>
        <div className="col-sm">
          <a href="/drone" className="text-dark">
            <img src="/images/gr.jpg" className="p-3 img-fluid" alt="Drone Page" />
          </a>
          <a href="drone" className="text-dark">
            <h3 className="mt-3">Drone</h3>
          </a>
        </div>
      </div>

      <div className="my-5 row">
        <div className="col-sm">
          <div className="videoWrapper">
            <iframe title="Southern Utah" src="https://www.youtube.com/embed/xbXg6rCvK9o" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>


        <div className="col-sm">
          <div className="videoWrapper">
            <iframe title="Mt Timpanogos" src="https://www.youtube.com/embed/Ejgd_6LjSCQ" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>


        <div className="col-sm">
          <div className="videoWrapper">
            <iframe title="Bozeman Sunrise" src="https://www.youtube.com/embed/4wkScC4fnak" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </>
  )
}
