import React from 'react';

export default function Contact() {
    return (
        <>
            <img src="/images/contact.jpg" className="img-fluid" alt="Contact Banner" />
            <div className="my-5 row">
                <div className="col-sm text-left px-5">
                    <a className="text-dark text-left" href="https://github.com/aj-medianet" target="_blank" rel="noopener noreferrer"><strong>GitHub</strong>: https://github.com/aj-medianet</a><br />
                    <a className="text-dark text-left" href="mailto:andrew@aj-media.net" target="_blank" rel="noopener noreferrer"><strong>Email</strong>: andrew@aj-media.net</a><br />
                    <a className="text-dark text-left" href="https://www.linkedin.com/in/andrew-joseph-816624133/" target="_blank" rel="noopener noreferrer"><strong>LinkedIn</strong>: https://www.linkedin.com/in/andrew-joseph-816624133/"</a><br />
                    <a className="text-dark text-left" href="https://www.instagram.com/aj__media/" target="_blank" rel="noopener noreferrer"><strong>Instagram</strong>: https://www.instagram.com/aj__media/</a><br />
                </div>
            </div>
        </>
    )
}
