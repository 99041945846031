import React from 'react';

export default function Photography() {
   return (
      <>
         <img src="/images/photography.jpg" className="img-fluid" alt="Photography Banner" />
         <div className="row">
            <div className="col-sm photo">
               <img src="/images/zacpowpow.jpg" className="img-fluid" alt="zacpowpow" />
            </div>
            <div className="col-sm photo">
               <img src="/images/deathvalley.jpg" className="img-fluid" alt="deathvalley" />
            </div>
         </div>

         <div className="row">
            <div className="col-sm photo">
               <img src="/images/conventmesa.jpg" className="img-fluid" alt="conventmesa" />
            </div>
         </div>


         <div className="row">
            <div className="col-sm photo">
               <img src="/images/eclipse.jpg" className="img-fluid" alt="eclipse" />
            </div>
            <div className="col-sm photo">
               <img src="/images/ridge.jpg" className="img-fluid" alt="ridge" />
            </div>
         </div>

         <div className="row">
            <div className="col-sm photo">
               <img src="/images/lakeblanche.jpg" className="img-fluid" alt="lakeblanche" />
            </div>
            <div className="col-sm photo">
               <img src="/images/lasal.jpg" className="img-fluid" alt="lasal" />
            </div>
            <div className="col-sm photo">
               <img src="/images/lcc.jpg" className="img-fluid" alt="lcc" />
            </div>
         </div>

         <div className="row">
            <div className="col-sm photo">
               <img src="/images/pfeifferbeach.jpg" className="img-fluid" alt="pfeifferbeach" />
            </div>
         </div>

         <div className="row">
            <div className="col-sm photo">
               <img src="/images/wily.jpg" className="img-fluid" alt="wily.jpg" />
            </div>
            <div className="col-sm photo">
               <img src="/images/tonypow.jpg" className="img-fluid" alt="tonypow" />
            </div>
         </div>

         <div className="row">
            <div className="col-sm photo">
               <img src="/images/tetoncanyon.jpg" className="img-fluid" alt="tetoncanyon" />
            </div>
         </div>

         <div className="row">
            <div className="col-sm photo">
               <img src="/images/zacbookends.jpg" className="img-fluid" alt="zacbookends" />
            </div>
            <div className="col-sm photo">
               <img src="/images/tetonssunflare.jpg" className="img-fluid" alt="tetonssunflare" />
            </div>
            <div className="col-sm photo">
               <img src="/images/saltflats.jpg" className="img-fluid" alt="saltflats" />
            </div>
         </div>
      </>
   )
}
