import React from 'react';

export default function Software() {
    return (
        <>
            <img src="/images/software.jpg" className="img-fluid" alt="Software Banner" />
            <div className="my-5 row">
                <div className="col-sm">
                    <a href="https://github.com/aj-medianet" className="text-dark" target="_blank" rel="noopener noreferrer">
                        <img src="/images/github.png" className="p-3 img-fluid" alt="Github Page" />
                    </a>
                    <a href="https://github.com/aj-medianet" className="text-dark" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-3">View my Github</h3>
                    </a>
                </div>
                <div className="col-sm">
                    <a href="https://github.com/aj-medianet/snowdata.org" className="text-dark" target="_blank" rel="noopener noreferrer">
                        <img src="/images/snowdata.png" className="p-3 img-fluid" alt="Snowdata.org Page" />
                    </a>
                    <a href="https://github.com/aj-medianet/snowdata.org" className="text-dark" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-3">SnowData.org FOSS Project</h3>
                    </a>
                </div>


                <div className="col-sm">
                    <a href="https://icalgenerator.net/" className="text-dark" target="_blank" rel="noopener noreferrer">
                        <img src="/images/ical.png" className="img-fluid" alt="iCal Generator Page" />
                    </a>
                    <a href="https://icalgenerator.net/" className="text-dark" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-3">iCal Generator Web App</h3>
                    </a>
                </div>
            </div>


        </>

    )
}
