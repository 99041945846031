import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, } from  'react-router-dom';
import './App.css';

import Navigation from './components/Nav'
import Footer from './components/FooterContent'
import MainContent from './components/MainContent'
import Software from './components/Software'
import Contact from './components/Contact'
import Photography from './components/Photography'
import Drone from './components/Drone'
import TimerPrivactPolicy from './components/TimerPrivactPolicy'


class App extends Component {
  constructor() {
    super()
    this.state = {

    }
  }
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navigation/>
            <Switch>
              <Route path="/" component={MainContent} exact/>
              <Route path="/software" component={Software} exact/>
              <Route path="/drone" component={Drone} exact/>
              <Route path="/photography" component={Photography} exact/>
              <Route path="/contact" component={Contact} exact/>
              <Route path="/random-game-timer-privacy-policy" component={TimerPrivactPolicy} exact/>
            </Switch>
          <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}
export default App